import React from "react";
import img01 from "../../assets/img/images/about_img01.png";
import img02 from "../../assets/img/images/about_img02.png";
import { Link } from "react-router-dom";
import WhoWeAreItem from "./WhoWeAreItem";

const WhoWeAre = (props) => {
const whoWeAreContent =  {
    src1: img01,
    src2: img02,
    alt: "",
    title: "Our Team",
    description: "Our team is made up of highly skilled professionals with extensive experience in the blockchain industry. We are passionate about using our expertise to develop solutions that will make a positive impact on people's lives. Our team is committed to providing excellent customer service and working closely with our clients to understand their needs and develop tailored solutions that meet their specific requirements. We take pride in our collaborative approach, which ensures that we deliver the best possible results for our clients.",
  }
  return (
    <section id="about" className="about-area text-centre">
      <div className="container">
      <WhoWeAreItem item = {whoWeAreContent}/>
      </div>
    </section>
  );
};

export default WhoWeAre;
