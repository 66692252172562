import React from "react";
import { Link } from "react-router-dom";
import AutoplayVideo from "../Video/AutoPlayVideo";

const WhoWeAreVideo = (props) => {
    return (
        <div className="row align-items-center">
          <div className="col-lg-12">
          <div className="about-content wow fadeInRight" data-wow-delay=".2s">
              <div className="section-title mb-30">
              <AutoplayVideo src= {props.item.src} width= "videoWidth"/>
          </div>
          </div>
          </div>
        </div>
    );
};

export default WhoWeAreVideo;
