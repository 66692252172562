import React from "react";
import { Link } from "react-router-dom";
import HeaderOne from "../Header/HeaderOne";
import ContactOne from "./ContactOne";
import FooterOne from "../Footer/FooterOne";

const CotactMain = (props) => {
  return (
    <>
    <HeaderOne/>
    <ContactOne/>
    <FooterOne/>
    </>
  );
};

export default CotactMain;
