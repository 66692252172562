import React from "react";
import keyPointIcon from "../../assets/img/images/key_point.png";
import { Link } from "react-router-dom";

const WhyChooseUsItem = (props) => {
  return (
    <div className="choose-item">
      <div className="choose-icon">
      <img src={keyPointIcon} alt="key_point" />
      </div>
      <div className="choose-content">
        <h2 className="title">
          {props.item.title}
        </h2>
      </div>
    </div>
  );
};

export default WhyChooseUsItem;
