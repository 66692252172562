import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import HeaderOne from '../../components/Header/HeaderOne';
import FooterOne from '../../components/Footer/FooterOne';
import auth from '../Auth/auth';

const List = ({setAlert}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState('All');
  const [selectedUser, setSelectedUser] = useState(null); 
  const [showPopup, setShowPopup] = useState(false);
  const history = useNavigate();

  const fetchAllUserData = async () => {
    try {
      const response = await auth.fetchAllUserData();
      if (response.status === 201 || response.status === 200) {
        setData(response.data.escManageUserRegisterList);
        setLoading(false);
      } else {
      }
    } catch (error) {
    }
  };

  useEffect(() => {
    fetchAllUserData();
  }, []);

//   const handleApprove = async (userId) => {
//     try {
//       const response = await auth.approveUserById(userId);
//       if (response.status === 200 || response.status === 201) {
//         setAlert({ message: 'User Kyc approved.', type: 'success' })
//         fetchAllUserData();
//       } else {
//         setAlert({ message: `Unable to approve user ${userId}.`, type: 'danger' });
//       }
//     } catch (error) {
//       setAlert({ message: 'An error occurred while verifying the user.', type: 'danger' });
//     }
//   };

//   const handleDecline = async (userId) => {
//     try {
//       const response = await auth.declineUserById(userId);
//       if (response.status === 200 || response.status === 201) {
//         setAlert({ message: 'User Kyc declined.', type: 'success' })
//         fetchAllUserData();
//       } else {
//         setAlert({ message: `Unable to decline user ${userId}.`, type: 'danger' });
//       }
//     } catch (error) {
//       setAlert({ message: 'An error occurred while verifying the user.', type: 'danger' });
//     }
//   };

  const handlePortfolio = async (userId) => {
    try {
      // Fetch user data for the selected user
      // const response = await auth.fetchUserData(userId);
      // const userData = await response.json();
      setSelectedUser(userId); // Set selected user
      setShowPopup(true); // Show popup
    } catch (error) {
      console.error('Error fetching user data:', error);
      // Handle error
      // setAlert({ message: 'An error occurred while fetching user data.', type: 'danger' });
    }
  };
  const closePopup = () => {
    setSelectedUser(null); // Reset selected user
    setShowPopup(false); // Hide popup
  };
//   const handleReminder = async (userId) => {
//     try {
//       const response = await auth.reminderUserById(userId);
//       if (response.status === 200 || response.status === 201) {
//         setAlert({ message: 'User Kyc approved.', type: 'success' })
//         fetchAllUserData();
//       } else {
//         setAlert({ message: `Unable to approve user ${userId}.`, type: 'danger' });
//       }
//     } catch (error) {
//       setAlert({ message: 'An error occurred while verifying the user.', type: 'danger' });
//     }
//   };

  return (
    <>
      <section>
        <div className='bg-white table-padding pt-1'>
          {loading ? (
            <p>Loading...</p>
          ) : (
            <>
              <div class="row" style={{overflow: 'auto'}}>
              <table className="table table-striped table-hover table-sm">
                <thead>
                  <tr className='text-center'>
                    <th>User ID</th>
                    <th>Verified</th>
                    <th>Invested Amount</th>
                    <th>Wallet Balance</th>
                    <th>Portfolio Balance</th>
                    <th>Invested Coin</th>
                    <th>Investment Duration</th>
                    <th>Portfolio Details</th>
                  </tr>
                </thead>
                <tbody>
                  {data.filter(item => item.isKycVerified === 'Y').map(item => (
                    <tr key={item.id} className='text-center'>
                      <td>{item.userName}</td>
                      <td>{item.aadhaarHolderName}</td>
                      <td>{item.panHolderName}</td>
                      <td>{item.accountHolderName}</td>
                      <td>{item.isKycVerified}</td>
                      <td>{item.accountHolderName}</td>
                      <td>{item.isKycVerified}</td>
                      <td><button className="btn btn-outline btn-success btn-sm btn-user-list" onClick={() => handlePortfolio(item.userId)}>View Portfolio</button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              </div>
            </>
          )}
        </div>
      </section>
    </>
  );
};

export default List;
