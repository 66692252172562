import React, { useState } from 'react';
import { Link, NavLink, useLocation } from "react-router-dom";
import cn from "classnames";
import { useNavigate } from 'react-router-dom'; // Import useHistory
import axios from 'axios';
import auth from '../Auth/auth';
import jsonp from 'jsonp';

const LogIn = ({ setAlert }) => {
  const history = useNavigate(); // Initialize useHistory
  const [userId, setUserId] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [loginAttempts, setLoginAttempts] = useState(0);

  const handlePasswordToggle = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const { hash, pathname } = useLocation();
  const handleCancel = () => {
    history('/'); // This function navigates to the previous page in the history.
  };

  const handleLogIn = async (e) => {
    e.preventDefault();

    if (!userId) {
      setErrorMessage('UserId is required.');
      setAlert({message:'UserId is required.', type: 'danger'});
      return;
    }
    if (!password) {
      setLoginAttempts(loginAttempts + 1);
      setErrorMessage('Password is required.');
      setAlert({message:'Password is required.', type: 'danger'});
      return;
    }
    if (userId && password) {
      // Increment login attempts
      if (loginAttempts >= 5) {
        setErrorMessage('Unable to login, please try reset password.');
        setAlert({message:'Unable to login, please try reset password.', type: 'danger'});
        return;
      } 
    }
        try {
          const response = await auth.login(userId, password);
          if (response.status === 201 || response.status === 200) {
            setAlert({message:'User login successfully!', type: 'success'});
            sessionStorage.setItem('userId', userId);
            history('/', { state: { userEmail: userId } });
            const userDetails = await auth.fetchUserData();
            if (userDetails.status === 201 || userDetails.status === 200) {
              sessionStorage.setItem('isKycVerified', userDetails.data.escManageUserRegister.isKycVerified);
              sessionStorage.setItem('userDetails', JSON.stringify(userDetails.data.escManageUserRegister));
            } else {
              setAlert({message:'Unable to fetch data.', type: 'danger'});
            }
          } else {
            setAlert({message:'Failed to login.', type: 'danger'});
            setLoginAttempts(loginAttempts + 1);
          }
        } catch (error) {
          console.error('Error creating user:', error);
          setAlert({message:'An error occurred while creating the user.', type: 'danger'});
        }
  };

  return (
    <section id="sales" className="chart-area sign-bg">
      <div className="container">
        <div className="chart-inner">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-6 col-md-10 order-0 order-lg-2">
              <div className="chart-wrap wow fadeIn" data-wow-delay=".2s">
              <li className={cn("text-end", pathname == "/sign" && "active sign")}>
                          <Link to="/sign">
                            Sign Up
                          </Link>
                        </li>
                <div className="sign-form-wrap mb-3">
                  <h4 className='text-center mt-2 mb-2'>Log In</h4>
                  <form onSubmit={handleLogIn}>
                    <div className="row mt-2">
                      <div className="form-grp">
                        <label>UserId</label>
                        <input
                          type="text"
                          value={userId}
                          onChange={(e) => setUserId(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="form-grp mt-2">
                      <label>Password</label>
                      <div className="password-input-container">
                      <input
                        type={showPassword ? 'text' : 'password'}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <button type="button" style={{ color: 'black' }} onClick={handlePasswordToggle}>
                        {showPassword ? <i class="fa fa-eye" aria-hidden="true"></i> : <i class="fa fa-eye-slash" aria-hidden="true"></i>}
                      </button>
                      </div>
                    </div>
                    <div className="submit-btn text-center mt-5">
                      {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
                      <button type="submit" className='btn'>Log In</button> &nbsp; &nbsp;
                      <button className='btn' onClick={handleCancel}>Cancel</button>
                    </div>
                    <div className="submit-btn text-center mt-3">
                    <p>Forgot your password? <Link to="/forgot-password">Reset it here</Link>.</p>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LogIn;
