import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useHistory
import jsonp from 'jsonp';
import auth from '../Auth/auth';
import PageLoader from '../../components/PageLoader/PageLoader';

const AadhaarCardForm = ({ setAlert }) => {
    const [aadhaarNumber, setAadhaarNumber] = useState('');
    const [fullName, setFullName] = useState('');
    const [dateOfBirth, setDateOfBirth] = useState('');
    const [aadhaarFrontImage, setAadhaarFrontImage] = useState(null);
    const [aadhaarBackImage, setAadhaarBackImage] = useState(null);
    const [submitMessage, setSubmitMessage] = useState('');
    const [loading, setLoading] = useState(false);

    const history = useNavigate(); // Initialize useHistory

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!aadhaarNumber) {
            setAlert({message:'Aadhaar Number is required.', type: 'danger'});
            return;
          }
           
        let setErrorMessage = '';
        let data = {
            adhaar : aadhaarNumber
        }
        try {
            setLoading(true);
            const response = await auth.verifyAdhaar(data);
            if (response.kycApiResponseStatus == 200) {
                setAlert({message:'Otp has been sent on your registered mobile number.', type: 'success'});
                setLoading(true);
                history('/otp-verification',  { state: { adhaar: response.aadhaarNo, refId : response.refId} });
                
            } else {
                const data = await response.json();
                setErrorMessage(data.message || 'An error occurred during sign-in.');
            }
            } catch (error) {
            setErrorMessage('An error occurred during sign-in.');
            console.error('Error signing in:', error);
            }
            finally {
                setLoading(false); // Deactivate loader
            }
    };

    return (
        <>
        {loading && <PageLoader />}
        <section id="sales" className="chart-area sign-bg">
            <div className="container">
                <div className="chart-inner">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-lg-6 col-md-10 order-0 order-lg-2">
                            <div className="chart-wrap wow fadeIn" data-wow-delay=".2s">
                                <div className="sign-form-wrap">
                                    <h2>Aadhaar Card Details</h2>
                                    <form onSubmit={handleSubmit}>
                                        <div className='form-grp'>
                                            <label htmlFor="aadhaarNumber">Aadhaar Number:</label>
                                            <input
                                                type="text"
                                                id="aadhaarNumber"
                                                value={aadhaarNumber}
                                                onChange={(e) => setAadhaarNumber(e.target.value)}
                                                required
                                            />
                                        </div>
                                        {/* <div className='form-grp'>
                                            <label htmlFor="fullName">Full Name:</label>
                                            <input
                                                type="text"
                                                id="fullName"
                                                value={fullName}
                                                onChange={(e) => setFullName(e.target.value)}
                                                required
                                            />
                                        </div> */}
                                        {/* <div className='form-grp'>
                                            <label htmlFor="dateOfBirth">Date of Birth:</label>
                                            <input
                                                type="date"
                                                id="dateOfBirth"
                                                value={dateOfBirth}
                                                onChange={(e) => setDateOfBirth(e.target.value)}
                                                required
                                            />
                                        </div>
                                        <div className='form-grp'>
                                            <label htmlFor="aadhaarFrontImage">Upload Aadhaar Front Image:</label>
                                            <input
                                                type="file"
                                                id="aadhaarFrontImage"
                                                accept="image/*"
                                                onChange={(e) => setAadhaarFrontImage(e.target.files[0])}
                                                required
                                            />
                                        </div> */}
                                        {/* <div className='form-grp'>
                                            <label htmlFor="aadhaarBackImage">Upload Aadhaar Back Image:</label>
                                            <input
                                                type="file"
                                                id="aadhaarBackImage"
                                                accept="image/*"
                                                onChange={(e) => setAadhaarBackImage(e.target.files[0])}
                                                required
                                            />
                                        </div> */}
                                        <div className="submit-btn text-center">
                                            <button className='btn' onClick={handleSubmit}>Send Otp</button>
                                        </div>
                                    </form>
                                    <p>{submitMessage}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    );
};

export default AadhaarCardForm;
