import React, { useState } from 'react';
import cn from 'classnames';
const verified = false;

const ProfileTab = ({ activeTab, onTabChange }) => {
  const infoItems = [
    {
      id: 'Profile',
      icon: 'fa-user',
      content: 'Profile',
    },
    {
      id: 'KYC Verification',
      icon: verified ? 'fa-user-check' : 'fa-user-shield',
      content: 'KYC Verification',
    },
    {
      id: 'Bank Account Details',
      icon: 'fa-id-card',
      content: 'Bank Account Details',
    },
    {
      id: 'UPI Details',
      icon: 'fa-location-arrow',
      content: 'UPI Details',
    }
  ];

  const [activeContent, setActiveContent] = useState(infoItems[0].id);

  const handleTabClick = (tabId) => {
    onTabChange(tabId);
    setActiveContent(tabId);
  };

  return (
    <div className="contact-info-wrap">
      <div className="row justify-content-center">
        <div className="btn-group mt-5 d-contents" role="group">
          {infoItems.map((tab) => (
            <div
              key={tab.id}
              className={cn("contact-info-item profile-header", activeTab === tab.id ? "active" : "")}
              onClick={() => handleTabClick(tab.id)}
            >
              <div className={cn("icon", activeTab === tab.id ? "active" : "")}>
                <span className={cn("icon-background", activeTab === tab.id ? "active" : "")}></span>
                <i className={cn("fas", tab.icon)}></i>
              </div>
              <div className="content">
                <p>{tab.content}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* <div className="fixed-content">
        <p>{infoItems.find((item) => item.id === activeContent)?.content}</p>
      </div> */}
    </div>
  );
};

export default ProfileTab;
