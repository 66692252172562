import React from "react";
import shape01 from "../../assets/img/banner/banner_shape01.png";
import shape02 from "../../assets/img/banner/banner_shape02.png";
import shape03 from "../../assets/img/banner/banner_shape03.png";
import fireIcon from "../../assets/img/icon/fire.png";
import CountDownOne from "../CountDown/CountDownOne";
import AutoplayVideo from "../Video/AutoPlayVideo";
import bannerVideo from "../../assets/video/banner_bg.mp4";
import bannerImg from "../../assets//img/images/bannerImage.png";

const Banner = (props) => {
  const partners_list = [
    { title: "5 Years Experience" },
    { title: "28 Current Investments" },
    { title: "2 Experienced Partners" },
  ];
  return (
    <section className="banner-area">
      {/* <div className="banner-shape-wrap">
        <img src={shape01} alt="" className="img-one" />
        <img src={shape02} alt="" className="img-two" />
        <img src={shape03} alt="" className="img-three" />
      </div> 
      <AutoplayVideo  src= {bannerVideo} width= "BannerWidth"/>
      <div className="container text-overlay">
        <div className="row justify-content-center">
          <div className="col-lg-10 mt-5">
            <div className="banner-content text-center">
              <h2 className="title">
                Evershine Cosmic <br />
                #INVESTWITHUS
              </h2>
            </div>*/}

            <img src={bannerImg} alt="banner_img" />
            <div className="container text-overlay">
              <div className="row justify-content-center">
                <div className="col-lg-10 mt-5">
                  <div className="banner-content text-center">
                    
            </div>  
            <div className="banner-progress-wrap p-5">
              {/* <ul>
                <li>Pre Sell</li>
                <li>Soft Cap</li>
                <li>Bonus</li>
              </ul>
              <div className="progress">
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{ width: "75%" }}
                  aria-valuenow="75"
                  aria-valuemin="0"
                  aria-valuemax="100"
                />
              </div> */}
              {/* <h4 className="title">
                65% target raised <span>1 ETH = $1000 = 3177.38 CIC</span>
            </h4>*/}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
