import React from "react";
import img01 from "../../assets/img/partner/partner_img01.png";
import img02 from "../../assets/img/partner/partner_img02.png";
import img03 from "../../assets/img/partner/partner_img03.png";
import img04 from "../../assets/img/partner/partner_img04.png";
import img05 from "../../assets/img/partner/partner_img05.png";
import img06 from "../../assets/img/partner/partner_img06.png";
import img07 from "../../assets/img/partner/partner_img07.png";
import img08 from "../../assets/img/partner/partner_img08.png";
import img09 from "../../assets/img/partner/partner_img09.png";
import img10 from "../../assets/img/partner/partner_img10.png";
import WhyChooseUsItem from "../WhyChooseUs/WhyChooseUsItem";

const TopPartners = () => {
  const partners_list = [
    { title: " MINIMUM INVESTMENT PERIOD IS 5 YEARS." },
    {  title: "INVEST JUST 10% OF YOUR TOTAL INV. CAPITAL." },
    {  title: "YOUR INVESTMENTS ARE SUBJECTED TO MARKET RISKS." },
  ];

  return (
    <div className="partner-area pb-130">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="section-title text-center mb-10">
              <span className="sub-title mt-5">Important Key Points</span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="partner-wrap">
            <div className="row justify-content-center">
              {partners_list.map((member, index) => (
                <div key={index} className="col-xl-4 col-md-4 col-sm-6 mt-4">
                  <WhyChooseUsItem item={member} />
                </div>
              ))}
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopPartners;
