import React from "react";
import { Link } from "react-router-dom";
import HeaderOne from "../../components/Header/HeaderOne";
import FooterOne from "../../components/Footer/FooterOne";
import Wallet from "./walletDetails";

const walletMain = ({setAlert}) => {
    return (
        <>
        <HeaderOne/>
        <Wallet setAlert={setAlert}/>
        <FooterOne/>
        </>
    );
};

export default walletMain;
