import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useHistory

const PhoneVerification = () => {
  const history = useNavigate(); // Initialize useHistory
  const [phoneNumber, setphoneNumber] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleSignIn = async () => {
    if (!phoneNumber) {
      setErrorMessage('phoneNumber is required.');
      return;
    }

    // try {
    //   const response = await fetch('https://localhost:8000/application', {
    //     method: 'POST',
    //     headers: {
    //       'Content-Type': 'application/json',
    //     },
    //     body: JSON.stringify({ email, password }),
    //   });

    //   if (response.ok) {
    //     setErrorMessage('');
    //     console.log('Sign-in logic:', email, password, rememberMe, selectedCountry);
    //     //remove when api ready
    //     // history('/', { state: { userEmail: email } }); // Pass email as state
    //     history('/otp-verification', { state: { userPhoneNumber: phoneNumber } });// Redirect to OTP verification page

    //   } else {
    //     const data = await response.json();
    //     setErrorMessage(data.message || 'An error occurred during sign-in.');
    //   }
    // } catch (error) {
    //   setErrorMessage('An error occurred during sign-in.');
    //   console.error('Error signing in:', error);
    // }
  };

  return (
    <section id="sales" className="chart-area sign-bg">
      <div className="container">
        <div className="chart-inner">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-6 col-md-10 order-0 order-lg-2">
              <div className="chart-wrap wow fadeIn" data-wow-delay=".2s">
                <div className="sign-form-wrap">
                  <h4 className='text-center'>Link Phone Number</h4>
                  <form action="#">
                    <div className="row">
                      <div className="form-grp">
                        <label>Mobile Number</label>
                        <input
                          type="number"
                          value={phoneNumber}
                          onChange={(e) => setphoneNumber(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="submit-btn text-center">
                      {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
                      <button className='btn' onClick={handleSignIn}>Sign In</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PhoneVerification;
