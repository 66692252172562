import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import auth from '../Auth/auth';

const QRCodePopup = ({ isOpen, onClose, qrImageUrl, setAlert }) => {
    const [transactionId, setTransactionId] = useState('');
    const [amount, setAmount] = useState('');
  const handleCancel = () => {
    onClose();
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    let setErrorMessage = '';
    let data = {
      "transactionId" : transactionId,
      "transactionAmount" : amount,
      "transactionStatus" : 'InProgress',
      "transactionType" : 'Mannual'
    }
    try {
        const response = await auth.addUserFund(data);
        if (response.status == 200 || response.status == 201) {
            setAlert({message:'Transaction complete, balance will reflect in account within few minutes', type: 'success'});
            handleCancel();
        } else {
            const data = await response.json();
            setErrorMessage(data.message || 'An error occurred during sign-in.');
        }
        } catch (error) {
        setErrorMessage('An error occurred during sign-in.');
        }
};

  return (
    <Modal show={isOpen} onHide={onClose} centered>
    <Modal.Header closeButton>
      <Modal.Title>Scan QR <br/> <div className='fs-medium'>UPI ID: <strong>evershinecosmic@ybl</strong></div></Modal.Title>
    </Modal.Header>
    <Modal.Body>
       <div style={{textAlign: 'center' }}>
       <img src={qrImageUrl} alt="QR Code" />
       </div>

    </Modal.Body>
    <div className="chart-wrap" style={{ borderRadius: '0px' }}>
                <div className="sign-form-wrap">
                  <h4 className='text-center'>Claim now</h4>
                  <Form>
          <Form.Group controlId="formTransactionId">
            <Form.Label>Transaction ID</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter transaction ID"
              value={transactionId}
              onChange={(e) => setTransactionId(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="formAmount">
            <Form.Label>Amount</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter amount"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
            />
          </Form.Group>
          <div className="text-center mt-3">
              <Button variant="secondary" onClick={handleSubmit}>
                Submit
              </Button>{' '}
              <Button variant="secondary" onClick={handleCancel}>
                Cancel
              </Button>
            </div>
          </Form>
                </div>
                </div>

  </Modal>

  );
};

export default QRCodePopup;
