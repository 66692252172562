import React from "react";
import { Link } from "react-router-dom";
import Header from "../../components/Header/HeaderOne";
import Footer from "../../components/Footer/FooterOne";
import List from "../../pages/Transactions/list.jsx";

const userMain = ({setAlert}) => {
    return (
        <>
        <Header/>
        <List setAlert={setAlert}/>
        <Footer/>
        </>
    );
};

export default userMain;
