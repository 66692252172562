import React, { useState, useEffect } from 'react';
import cn from "classnames";
import { useNavigate } from 'react-router-dom';
import HeaderOne from '../../components/Header/HeaderOne';
import FooterOne from '../../components/Footer/FooterOne';
import auth from '../Auth/auth';

const List = ({ setAlert }) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [activeTab, setActiveTab] = useState('All');
    const [selectedUser, setSelectedUser] = useState(null);
    const [showPopup, setShowPopup] = useState(false);
    const history = useNavigate();

    const fetchAllUserTransactions = async () => {
        try {
            const response = await auth.getUserTransactions();
            console.log(response);
            if (response.status === 201 || response.status === 200) {
                setData(response.data.escUserTransactionList);
                setLoading(false);
            } else {
            }
        } catch (error) {
        }
    };

    useEffect(() => {
        fetchAllUserTransactions();
    }, []);

    const handleApprove = async (transaction) => {
        try {
            const response = await auth.approveTransactionById(transaction);
            if (response.status === 200 || response.status === 201) {
                setAlert({ message: 'User transaction approved.', type: 'success' })
                fetchAllUserTransactions();
            } else {
                setAlert({ message: `Unable to approve user ${transaction.userId}.`, type: 'danger' });
            }
        } catch (error) {
            setAlert({ message: 'An error occurred while verifying the user.', type: 'danger' });
        }
    };

    const handleDecline = async (transactionId) => {
        try {
            const response = await auth.declineTransactionById(transactionId);
            if (response.status === 200 || response.status === 201) {
                setAlert({ message: 'User transaction declined.', type: 'success' })
                fetchAllUserTransactions();
            } else {
                setAlert({ message: `Unable to decline user ${transactionId}.`, type: 'danger' });
            }
        } catch (error) {
            setAlert({ message: 'An error occurred while verifying the user.', type: 'danger' });
        }
    };

    const handlePortfolio = async (userId) => {
        try {
            // Fetch user data for the selected user
            // const response = await auth.fetchUserData(userId);
            // const userData = await response.json();
            setSelectedUser(userId); // Set selected user
            setShowPopup(true); // Show popup
        } catch (error) {
            console.error('Error fetching user data:', error);
            // Handle error
            // setAlert({ message: 'An error occurred while fetching user data.', type: 'danger' });
        }
    };
    const closePopup = () => {
        setSelectedUser(null); // Reset selected user
        setShowPopup(false); // Hide popup
    };
    //   const handleReminder = async (userId) => {
    //     try {
    //       const response = await auth.reminderUserById(userId);
    //       if (response.status === 200 || response.status === 201) {
    //         setAlert({ message: 'User Kyc approved.', type: 'success' })
    //         fetchAllUserData();
    //       } else {
    //         setAlert({ message: `Unable to approve user ${userId}.`, type: 'danger' });
    //       }
    //     } catch (error) {
    //       setAlert({ message: 'An error occurred while verifying the user.', type: 'danger' });
    //     }
    //   };
    const tabNames = {
        'All': 'All',
        'N': 'Non-Verified Transactions',
        'Y': 'Verified Transactions'
      };
    
      // Filter data based on selected tab
      const filteredData = data.filter(item => {
        if (activeTab === 'InProgress') return true;
        return item.isTransactionVerified === activeTab;
      });

    return (
        <>
            <section>
                <div className='bg-white table-padding pt-1'>
                    {loading ? (
                        <p>Loading...</p>
                    ) : (
                        
                        <>
                        <div className="tabs mt-3 mb-3 mobList" >
                {Object.keys(tabNames).map(status => (
                  <button
                    key={status}
                    className={status === activeTab ? 'active-tab btn btn-user-list' : 'btn btn-user-list'}
                    onClick={() => setActiveTab(status)}
                  >
                    {tabNames[status]}
                  </button>
                ))}
              </div>
                            <div class="row" style={{ overflow: 'auto' }}>
                                <table className="table table-striped table-hover table-sm">
                                    <thead>
                                        <tr className='text-center'>
                                            <th>User ID</th>
                                            <th>Transaction Id</th>
                                            <th>Transaction Amount</th>
                                            <th>Transaction Date/Time</th>
                                            <th>Transaction Type</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredData.map(item => (
                                            <tr key={item.id} className='text-center'>
                                                <td>{item.userId}</td>
                                                <td>{item.transactionId}</td>
                                                <td>{item.transactionAmount}</td>
                                                <td>{item.transactionDate}</td>
                                                <td>{item.transactionType}</td>
                                                <td>
                                                    <i
                                                        className={cn("fas", 'fa-thumbs-up')}
                                                        onClick={() => handleApprove(item)} 
                                                        style={{ cursor: 'pointer', color: "green" }} 
                                                    ></i>&nbsp;&nbsp;&nbsp;
                                                    <i
                                                        className={cn("fas", 'fa-thumbs-down')}
                                                        onClick={() => handleDecline(item.transactionId)} 
                                                        style={{ cursor: 'pointer', color: 'red' }} 
                                                    ></i>&nbsp;&nbsp;&nbsp;
                                                    <i
                                                        className={cn("fas", 'fa-user')}
                                                        onClick={() => handlePortfolio(item.transactionId)} 
                                                        style={{ cursor: 'pointer' }} 
                                                    ></i>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </>
                    )}
                </div>
            </section>
        </>
    );
};

export default List;
