import jsonp from 'jsonp';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useHistory
import auth from '../Auth/auth';


const PanCardForm = ({setAlert}) => {
    const [panNumber, setPanNumber] = useState('');
    const [fullName, setFullName] = useState('');
    const [dateOfBirth, setDateOfBirth] = useState('');
    const [panImage, setPanImage] = useState(null);
    const [submitMessage, setSubmitMessage] = useState('');
    const [ErrorMessage, setErrorMessage] = useState('');
    const history = useNavigate(); // Initialize useHistory

    const handleSubmit = async (e) => {
        e.preventDefault();
        let setErrorMessage = '';
        const data = {
            pan : panNumber,
            nameAsPan : fullName,
            dateOfBirth: dateOfBirth
        }

        console.log(data.pan);
        try {
        const response = await auth.verifyPan(data);

        if (response.status == 201 || response.status == 200) {
            setSubmitMessage('');
            history('/bankDetailsForm')
            setAlert({message:'We got your Pan details. Thank you for submitting.', type: 'success'});
        } else {
            const data = await response.data;
            setSubmitMessage(data.message || 'An error occurred during KYC.');
        }
        } catch (error) {
            setSubmitMessage('An error occurred during KYC.');
        console.error('Error signing in:', error);
        }
    };

    return (
        <section id="sales" className="chart-area sign-bg">
            <div className="container">
                <div className="chart-inner">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-lg-6 col-md-10 order-0 order-lg-2">
                            <div className="chart-wrap wow fadeIn" data-wow-delay=".2s">
                                <div className="sign-form-wrap">
                                    <h2>PAN Card Details</h2>
                                    <form onSubmit={handleSubmit}>
                                        <div className="form-grp">
                                            <label htmlFor="panNumber">PAN Number:</label>
                                            <input
                                                type="text"
                                                id="panNumber"
                                                value={panNumber}
                                                onChange={(e) => setPanNumber(e.target.value)}
                                                required
                                            />
                                        </div>
                                        <div className="form-grp">
                                            <label htmlFor="fullName">Name on Pan:</label>
                                            <input
                                                type="text"
                                                id="fullName"
                                                value={fullName}
                                                onChange={(e) => setFullName(e.target.value)}
                                                required
                                            />
                                        </div>
                                        <div className="form-grp">
                                            <label htmlFor="dateOfBirth">Date of Birth:</label>
                                            <input
                                                type="date"
                                                id="dateOfBirth"
                                                value={dateOfBirth}
                                                onChange={(e) => setDateOfBirth(e.target.value)}
                                                required
                                            />
                                        </div>
                                        {/* <div className="form-grp">
                                            <label htmlFor="panImage">Upload PAN Card Image:</label>
                                            <input
                                                type="file"
                                                id="panImage"
                                                accept="image/*"
                                                onChange={(e) => setPanImage(e.target.files[0])}
                                                required
                                            />
                                        </div> */}
                                        <div className="submit-btn text-center">
                                        <button className='btn' onClick={handleSubmit}>Submit</button>
                                        </div>
                                    </form>
                                    <p>{submitMessage}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default PanCardForm;
