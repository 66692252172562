import React, { useState } from 'react';

function ResetPassword() {
    const [otp, setOtp] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();
        // Handle password reset logic here
        if (password !== confirmPassword) {
            alert("Passwords don't match");
            return;
        }
        // Implement the logic to reset the password
        console.log('Password reset successful:', password);
        // Redirect user or display success message
    };

    return (
        <section id="sales" className="chart-area sign-bg">
            <div className="container">
                <div className="chart-inner">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-lg-6 col-md-10 order-0 order-lg-2">
                            <div className="chart-wrap wow fadeIn" data-wow-delay=".2s">
                                <div className="sign-form-wrap mb-3">
                                    <h4 className='text-center mt-2 mb-2'>Reset Password</h4>
                                    <form onSubmit={handleSubmit}>
                                        <div className="row mt-2">
                                            <div className="form-grp">
                                                <label htmlFor="password">Otp:</label>
                                                <input
                                                    type="number"
                                                    id="otp"
                                                    value={otp}
                                                    onChange={(e) => setOtp(e.target.value)}
                                                    required
                                                />
                                            </div>

                                        </div>
                                        <div className="row mt-2">
                                            <div className="form-grp">
                                                <label htmlFor="password">New Password:</label>
                                                <input
                                                    type="password"
                                                    id="password"
                                                    value={password}
                                                    onChange={(e) => setPassword(e.target.value)}
                                                    required
                                                />
                                            </div>

                                        </div>
                                        <div className="row mt-2">
                                            <div className="form-grp">
                                                <label htmlFor="confirmPassword">Confirm Password:</label>
                                                <input
                                                    type="password"
                                                    id="confirmPassword"
                                                    value={confirmPassword}
                                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                                    required
                                                />
                                            </div>

                                        </div>

                                        <div className="submit-btn text-center mt-5">
                                            <button type="submit" className='btn'>Reset Password</button> &nbsp; &nbsp;
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ResetPassword;
