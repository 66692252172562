import React from "react";
import { Link } from "react-router-dom";

const WhoWeAreItem = (props) => {
    return (
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="about-content wow fadeInLeft" data-wow-delay=".2s">
              <div className="section-title mb-30">
                <span className="sub-title">{props.item.subtitle}</span>
                <h2 className="title">
                {props.item.title}
                </h2>
              </div>
              <p className="align-justify">{props.item.description}</p>
            </div>
          </div>
          <div className="col-lg-6 col-md-10 order-0 order-lg-2">
            <div className="wow fadeInRight" data-wow-delay=".2s">
              <img src={props.item.src1} alt="" />
              <img src={props.item.src2} alt="" className="img-two" />
            </div>
          </div>
        </div>
    );
};

export default WhoWeAreItem;
