import React, { useState, useEffect } from "react";
import { Link, NavLink, Navigate, useLocation, useNavigate } from "react-router-dom";
import cn from "classnames";
import logoImage from "../../../src/assets/img/logo/logo1.jpg";
import $ from "jquery";
import { handleClickScroll } from "../../lib/helpers";
import auth from "../../pages/Auth/auth";
import WhyChooseUs from "../WhyChooseUs/WhyChooseUs";

const HeaderOne = () => {
  // sticky nav bar
  const [stickyClass, setStickyClass] = useState({
    fixed: "",
    header: "",
  });

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      // window height changed for the demo
      windowHeight < 245
        ? setStickyClass({ fixed: "", header: "" })
        : setStickyClass({ fixed: "active-height", header: "sticky-menu" });
    }
  };

  const history = useNavigate(); // Initialize useHistory
  const location = useLocation();
  useEffect(() => {
    window.addEventListener("scroll", stickNavbar);
    return () => window.removeEventListener("scroll", stickNavbar);
  }, []);  // mobile menu
  useEffect(() => {
    //SubMenu Dropdown Toggle
    if ($(".menu-area li.menu-item-has-children ul").length) {
      $(".menu-area .navigation li.menu-item-has-children").append(
        '<div class="dropdown-btn"><span class="fas fa-angle-down"></span></div>'
      );
    }
    //Mobile Nav Hide Show
    if ($(".mobile-menu").length) {
      let mobileMenuContent = $(".menu-area .main-menu").html();
      $(".mobile-menu .menu-box .menu-outer").append(mobileMenuContent);

      //Dropdown Button
      $(".mobile-menu li.menu-item-has-children .dropdown-btn").on(
        "click",
        function () {
          $(this).toggleClass("open");
          $(this).prev("ul").slideToggle(500);
        }
      );
      //Menu Toggle Btn
      $(".mobile-nav-toggler").on("click", function () {
        $("body").addClass("mobile-menu-visible");
        $(".mobile-menu .menu-box .menu-outer .navigation li").removeClass("d-none");
      });

      //Menu Toggle Btn
      $(".menu-backdrop, .mobile-menu .close-btn").on("click", function () {
        $("body").removeClass("mobile-menu-visible");
      });
    }

  }, []);

  // active link switching
  const { hash, pathname } = useLocation();
  const isActiveLink = (id) => {
    return id == hash ? "active" : "";
  };

  const [userData, setUserData] = useState({});
  const [redirectTo, setRedirectTo] = useState(null);

  var userId = auth.getUserId();
  useEffect(() => {
    const fetchData = async () => {
      const data = await auth.fetchUserData();
      if (data) {
        setUserData(data.data.escManageUserRegister);
      } else {
        setUserData(null);
      }
    };
    if (userId !== null) {
      fetchData();
    }
  }, []);
  useEffect(() => {
    if (userData && typeof userData.isKycVerified === "string") {
      if (userData.isKycVerified === "N") {
        setRedirectTo("/adharCard");
      } else if (userData.isKycVerified === "P") {
        setRedirectTo("/panCard");
      } else if (userData.isKycVerified === "B") {
        setRedirectTo("/bankDetailsForm");
      }
    }
  }, [userData]);

  const handleLogout = () => {
    localStorage.setItem('auth_token', ''); // Set it to an empty string
    sessionStorage.removeItem('userId');
    sessionStorage.removeItem('userDetails');
    sessionStorage.removeItem('isKycVerified');
    history('/');
  };


  return (
    <header id="header" className="mb-5">
      <div id="header-fixed-height" className={cn(stickyClass.fixed)} />

      <div id="sticky-header" className={cn("menu-area", stickyClass.header)}>
        <div className={cn("container custom-container")}>
          <div className="row">
            <div className="col-12">
              <div className={"mobile-nav-toggler"}>
                <i className="fas fa-bars" />
              </div>

              <div className="menu-wrap">
                <nav className={"menu-nav"}>
                  <div className="logo">
                    <Link to={"/"}>
                      <img src={logoImage} alt="EverShineCosmic Logo" />
                    </Link>
                  </div>

                  <div className={cn("navbar-wrap main-menu d-none d-lg-flex")}>
                    <ul className={"navigation"}>
                      <li
                        className={cn(
                          pathname == "/" && "active"
                        )}
                      >
                        <Link to="/">
                          {!userId ? <span>Home</span> : <span>Dashboard</span>}
                        </Link>
                      </li>
                      <li className={cn(pathname == "/about" && "active")}>
                        <Link
                          to="/about">
                          About us
                        </Link>
                      </li>
                      <li className={cn(pathname == "/contact" && "active")}>
                        <Link
                          to="/contact"
                        >
                          Contact us
                        </Link>
                      </li>
                      {userData.isSupervisor === "Y" && (
                        <li className={cn({ active: location.pathname === "/admin" })}>
                          {/*To do: modify to user */}
                          <Link to="/admin">Admin Panel</Link>
                        </li>
                      )}
                      {userData.isSupervisor === "Y" && (
                        <li className={"header-lang mt-4-5"}>
                          {/* To do: modify to selected item */}
                          <span className={"selected-lang"}>Users</span>
                          <ul className={"lang-list user-menu"}>
                            <>
                              <li className={cn(pathname == "/investment" && "active")}>
                                <Link to="/investment">Investments</Link>
                              </li>
                              <li className={cn(pathname == "/transaction" && "active")}>
                                <Link to="/transaction">Transactions</Link>
                              </li>
                            </>
                          </ul>
                        </li>
                      )}
                      {userId ? <li className={cn({ active: location.pathname === "/login" }, "d-none")}>
                        <Link to="/login" onClick={handleLogout}>
                          Logout
                        </Link>
                      </li> : ''}
                      {/* {userId && (
                      <li className={cn({ active: location.pathname === "/wallet" })}>
                        <Link to={userData.isKycVerified === "Y" ? "/wallet" : kycCheck}>
                          Wallet
                        </Link>
                      </li>
                    )} */}

                    </ul>
                  </div>
                  <div className={cn("header-action", "d-none d-md-block")}>
                    <ul>
                      {!userId ? (<>
                        <li className={cn(pathname == "/sign" && "active sign")}>
                          <Link to="/sign">
                            Sign Up
                          </Link>
                        </li>
                        <li className={cn(pathname == "/logIn" && "active sign")}>
                          <Link to="/login">
                            Log In
                          </Link>
                        </li>
                      </>
                      ) : (
                        <li className={"header-lang"}>
                          <span className={"selected-lang"}>{userId}</span>
                          <ul className={"lang-list"}>
                            {userData.isSupervisor === "Y" ? '' :
                              <>{!userId ? '' : <>
                                <li className={cn(pathname == "/profilePage" && "active")}>
                                  <Link to="/profilePage">Profile</Link>
                                </li>
                                <li
                                >
                                  <Link to={userData.isKycVerified == "Y" ? "/wallet" : redirectTo}>Wallet</Link>
                                </li></>
                              }</>
                            }

                            <li>
                              <Link to="/login" onClick={handleLogout} >Logout</Link> {/*<!-- add functionality -->*/}
                            </li>
                          </ul>
                        </li>

                      )
                      }
                    </ul>
                  </div>
                </nav>
              </div>

              {/* <!-- Mobile Menu  --> */}
              <div className={"mobile-menu"}>
                <nav className={"menu-box"}>
                  <div className={"close-btn"}>
                    <i className="fas fa-times"></i>
                  </div>
                  <div className={"nav-logo"}>
                    <Link to="/">
                      <img src={logoImage} alt="BigTech Logo" title="" />
                    </Link>
                  </div>

                  <div className={"menu-outer"}>
                    {/* <!--Here Menu Will Come Automatically Via Javascript / Same Menu as in Header-->  */}
                    <ul className={"navigation"}>

                      {!userId ? (<>
                        <li className={cn(pathname == "/sign" && "active sign")}>
                          <Link to="/sign">
                            Sign Up
                          </Link>
                        </li>
                        <li className={cn(pathname == "/logIn" && "active sign")}>
                          <Link to="/login">
                            Log In
                          </Link>
                        </li>
                      </>
                      ) : (
                        <>
                          {userData.isSupervisor === "Y" && (
                            <li className={cn({ active: location.pathname === "/admin" })}>
                              <Link to="/admin">Admin Panel</Link>
                            </li>
                          )}
                          {userData.isSupervisor != "Y" && userId && (
                            <>
                              <li className={cn({ active: location.pathname === "/wallet" })}>
                                <Link to={userData.isKycVerified === "Y" ? "/wallet" : redirectTo}>
                                  Wallet
                                </Link>
                              </li>

                              <li className={cn(pathname == "/profilePage" && "active")}>
                                <Link to="/profilePage">Profile</Link>
                              </li>
                            </>
                          )}
                        </>
                      )
                      }

                    </ul>
                  </div>

                  <div className={"social-links"}>
                    <ul className="clearfix">
                      <li>
                        <a href="https://youtube.com/@EvershineCosmic?si=Qrqs6Bx4mu5MIxJj" target="_blank">
                          <i className="fab fa-youtube"></i>
                        </a>
                      </li>
                      <li>
                        <a href="https://x.com/CosmicEvershine?t=CIJz_GKGGCWLLZM6k9JjLg&s=09" target="_blank">
                          <i className="fa-brands fa-x-twitter"></i>
                        </a>
                      </li>
                      <li>
                        <a href="https://instagram.com/evershinecosmic?igshid=MWZjMTM2ODFkZg==" target="_blank">
                          <i className="fab fa-instagram"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>

              <div className={"menu-backdrop"} />
              {/* <!-- End Mobile Menu --> */}
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default HeaderOne;
