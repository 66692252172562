// auth.js

import axios from 'axios';

const TOKEN_STORAGE_KEY = 'auth_token';
const REFRESH_TOKEN_STORAGE_KEY = 'refresh_token';
const isLocal = window.location.hostname === 'localhost';

const instance = axios.create({
  baseURL : isLocal ? 'http://localhost:8082/' : 'https://evershinecosmic.com/',
});

const getUserIdFromAuthToken = () => {
  const authToken = localStorage.getItem('auth_token');
  if (authToken) {
    const tokenPayload = authToken.split('.')[1];
    const decodedPayload = JSON.parse(atob(tokenPayload));
    return decodedPayload.sub;
  }
  return null;
};

const auth = {
  //authentication start

  getUserId() {
    return getUserIdFromAuthToken();
  },
  getToken() {
    return localStorage.getItem(TOKEN_STORAGE_KEY);
  },

  setToken(token) {
    localStorage.setItem(TOKEN_STORAGE_KEY, token);
  },

  getRefreshToken() {
    return localStorage.getItem(REFRESH_TOKEN_STORAGE_KEY);
  },

  setRefreshToken(refreshToken) {
    localStorage.setItem(REFRESH_TOKEN_STORAGE_KEY, refreshToken);
  },

  clearTokens() {
    localStorage.removeItem(TOKEN_STORAGE_KEY);
    localStorage.removeItem(REFRESH_TOKEN_STORAGE_KEY);
  },
// authentication end

// user data start
  async login(userId, password) {
    try {
      const response = await instance.post('api/v1/auth/login', { 
        "userId": userId,
        "password": password,
        },{
        headers: {
          'Content-Type': 'application/json',
          'Accept':'application/json',
          'Access-Control-Allow-Origin': '*'
        }
      });
      const { token, refreshToken } = response.data;

      auth.setToken(token);
      auth.setRefreshToken(refreshToken);

      return response; // Login success
    } catch (error) { 
      console.error('Login failed:', error);
      return error; // Login failed
    }
  },

  async fetchUserData() {
    try {
      const userId = getUserIdFromAuthToken();
      const response = await instance.post(`api/v1/auth/userById`, {
        "userId": userId
      }, {
        headers: { 
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${auth.getToken()}` },
      });
      return response; // Return specific user data
    } catch (error) {
      console.error('Error fetching user data:', error);
      return null;
    }
  },

  async fetchAllUserData() {
    try {
      const userId = getUserIdFromAuthToken();
      const response = await instance.post(`/kyc/getAllCustomers`, {
        "userId": userId
      }, {
        headers: { 
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${auth.getToken()}` },
      });
      return response; // Return specific user data
    } catch (error) {
      console.error('Error fetching user data:', error);
      return null;
    }
  },
// user data end

// kyc start
  async approveUserById(userId) {
    try {
      const response = await instance.post(`/kyc/verify`, {
        "userId": userId,
        "iskycVerifiedManually":"Y"
      }, {
        headers: { 
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${auth.getToken()}` },
      });
      
      return response; // Return specific user data
    } catch (error) {
      console.error('Error fetching user data:', error);
      return null;
    }
  },

  async declineUserById(userId) {
    try {
      const response = await instance.post(`/kyc/declineKYC`, {
        "userId": userId,
      }, {
        headers: { 
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${auth.getToken()}` },
      });
      
      return response; // Return specific user data
    } catch (error) {
      console.error('Error fetching user data:', error);
      return null;
    }
  },

  async verifyPan(data) {
    try {
      console.log( auth.getToken());

      const response = await instance.post(
        'kyc/pan/verify',
        {
          'pan': data.pan,
          'nameAsPan': data.nameAsPan,
          'dateOfBirth':data.dateOfBirth
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${auth.getToken()}`
          }
        }
      );
      return response; // Return specific user data
    } catch (error) {
      console.error('Error verifying PAN:', error);
      return null;
    }
  },

  async verifyNow() {
    try {
      console.log( auth.getToken());

      const response = await instance.post(
        'kyc/verify',
        {
          "userId": sessionStorage.getItem('userId'),
          "iskycVerifiedManually":"Y"
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${auth.getToken()}`
          }
        }
      );
      return response; // Return specific user data
    } catch (error) {
      console.error('Error verifying Data:', error);
      return null;
    }
  },
  
  async verifyAdhaar(data) {
    try {
      const response = await instance.post(`kyc/aadhaar/otp`, {
        "aadhaarNumber": data.adhaar
      }, {
        headers: { 
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${auth.getToken()}` },
      });
      return response.data; // Return specific user data
    } catch (error) {
      console.error('Error fetching user data:', error);
      return null;
    }
  },

  async verifyAdhaarOtp(data) {
    try {
      const response = await instance.post(`kyc/aadhaar/otp/verify`, {
        "aadhaarNo": data.aadhaarNo,
        "referenceId": data.refId,
        "oneTimePassword": data.otp 
      }, {
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${auth.getToken()}` },
      });
      return response.data; // Return specific user data
    } catch (error) {
      console.error('Error fetching user data:', error);
      return null;
    }
  },

  async verifyBankDetails(data) {
    try {
      const response = await instance.post(`kyc/bankAcc/pennyDrop/verify`, {
        "ifsc": data.ifsc,
        "bankAccountNo": data.bankAccountNo,
        "nameAsAadhaar":"",
        "nameAsPan":""
      }, {
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${auth.getToken()}` },
      });
      return response.data; // Return specific user data
    } catch (error) {
      console.error('Error fetching user data:', error);
      return null;
    }
  },
//kyc end

//wallet start
  async getCryptoRates(data) {
    try {
      const url = `http://api.coinlayer.com/api/live?access_key=${data.accessKey}&symbols=${data.symbols}`;
  
      const response = await axios.post(url);
      const rates = response.data.rates;

      // Convert rates object to array of objects
      const ratesArray = Object.keys(rates).map(currency => ({
        currency,
        rate: rates[currency]
      }));
  
      return ratesArray;
    } catch (error) {
      console.error('Error fetching cryptocurrency rates:', error);
      return null;
    }
  },

  async getWalletInfo(data) {
    try {
      const userId = getUserIdFromAuthToken();
      const response = await instance.post(`kyc/wallet`, {
        "userId" : userId
      }, {
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${auth.getToken()}` },
      });
      return response.data; // Return specific user data
    } catch (error) {
      console.error('Error fetching user data:', error);
      return null;
    }
  },

  async getWalletChart(data) {
    try {
      const userId = getUserIdFromAuthToken();
      const response = await instance.post(`kyc/wallet`, {
        "userId" : userId
      }, {
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${auth.getToken()}` },
      });
      return response.data; // Return specific user data
    } catch (error) {
      console.error('Error fetching user data:', error);
      return null;
    }
  },
//wallet end

//transaction start
async getUserTransactions(data) {
  try {
    const userId = getUserIdFromAuthToken();
    const response = await instance.post(`escosmic/getTransList`, {
      "userId" : userId
    }, {
      headers: { 
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${auth.getToken()}` },
    });
    return response; // Return specific user data
  } catch (error) {
    console.error('Error fetching user data:', error);
    return null;
  }
},

async addUserFund(data) {
  try {
    const response = await instance.post(`escosmic/user/transaction`, {
      "transactionId" : data.transactionId,
      "transactionAmount" : data.transactionAmount,
      "transactionStatus" : data.transactionStatus,
      "transactionType" : data.transactionType
    }, {
      headers: { 
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${auth.getToken()}` },
    });
    return response; // Return specific user data
  } catch (error) {
    console.error('Error fetching user data:', error);
    return null;
  }
},

async approveTransactionById(transaction) {
  try {
    const response = await instance.post(`escosmic/approveTransaction`, {
      "userId": transaction.userId,
      "transactionId": transaction.transactionId,
    }, {
      headers: { 
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${auth.getToken()}` },
    });
    
    return response; // Return specific user data
  } catch (error) {
    console.error('Error fetching user data:', error);
    return null;
  }
},

async declineTransactionById(userId) {
  try {
    const response = await instance.post(`escosmic/declineTransaction`, {
      "userId": userId,
    }, {
      headers: { 
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${auth.getToken()}` },
    });
    
    return response; // Return specific user data
  } catch (error) {
    console.error('Error fetching user data:', error);
    return null;
  }
},

async getUserWallet(data) {
  try {
    const userId = getUserIdFromAuthToken();
    const response = await instance.post(`wallet/getWalletData`, {
      "userId" : userId
    }, {
      headers: { 
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${auth.getToken()}` },
    });
    console.log(response);
    return response; // Return specific user data
  } catch (error) {
    console.error('Error fetching user data:', error);
    return null;
  }
},
//transaction end

  async refreshAccessToken() {
    try {
      const refreshToken = auth.getRefreshToken();
      const response = await instance.post('/refresh-token', { refreshToken });

      const newToken = response.data.token;
      auth.setToken(newToken);

      return newToken;
    } catch (error) {
      console.error('Error refreshing token:', error);
      return null;
    }
  },

  async handleTokenExpiration() {
    const token = auth.getToken();

    if (token) {
      const decodedToken = auth.decodeToken(token);

      if (decodedToken && decodedToken.exp * 1000 < Date.now()) {
        // Token has expired, try to refresh
        const newToken = await auth.refreshAccessToken();

        if (!newToken) {
          // Token refresh failed, logout user
          auth.logout();
        }
      }
    }
  },

  async logout() {
    auth.clearTokens();
    // Additional logout logic, if needed
  },

  attachTokenToRequest(config) {
    const token = auth.getToken();
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },

  attachTokenInterceptor() {
    return instance.interceptors.request.use(auth.attachTokenToRequest, (error) => Promise.reject(error));
  },

  detachTokenInterceptor(interceptorId) {
    instance.interceptors.request.eject(interceptorId);
  },

  attachTokenExpirationInterceptor() {
    return instance.interceptors.response.use(
      (response) => response,
      async (error) => {
        if (error.response && error.response.status === 401) {
          // Unauthorized, token expired, try to refresh
          const newToken = await auth.refreshAccessToken();

          if (newToken) {
            // Retry the original request with the new token
            error.config.headers['Authorization'] = `Bearer ${newToken}`;
            return instance(error.config);
          } else {
            // Token refresh failed, logout user
            auth.logout();
          }
        }
        return Promise.reject(error);
      }
    );
  },
};

export default auth;
