import React, { useRef, useEffect } from 'react';

const WalletChart = () => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    const chartData = {
      labels: ['January', 'February', 'March', 'April', 'May'],
      datasets: [
        {
          label: 'Sample Data',
          fill: false,
          lineTension: 0.5,
          borderColor: 'rgba(75,192,192,1)',
          borderWidth: 2,
          data: [65, 59, 80, 81, 56],
        },
      ],
    };

    drawLineChart(ctx, chartData);
  }, []);

  const drawLineChart = (ctx, data) => {
    const chartWidth = 400;
    const chartHeight = 200;
    const padding = 20;
    const maxValue = Math.max(...data.datasets[0].data);
    const scale = (chartHeight - padding * 2) / maxValue;
    const stepSize = (chartWidth - padding * 2) / (data.labels.length - 1);

    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

    ctx.beginPath();
    ctx.moveTo(padding, chartHeight - padding);

    data.datasets[0].data.forEach((value, index) => {
      const x = padding + index * stepSize;
      const y = chartHeight - padding - value * scale;
      ctx.lineTo(x, y);
    });

    ctx.strokeStyle = data.datasets[0].borderColor;
    ctx.lineWidth = data.datasets[0].borderWidth;
    ctx.stroke();

    ctx.fillStyle = 'black';
    ctx.font = '12px Arial';
    data.labels.forEach((label, index) => {
      const x = padding + index * stepSize;
      const y = chartHeight - padding + 15;
      ctx.fillText(label, x, y);
    });
  };

  return (
    <div>
      <h2>Simple Line Chart</h2>
      <canvas ref={canvasRef} width={400} height={200} />
    </div>
  );
};

export default WalletChart;
