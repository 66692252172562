import React from 'react';
import { Route, Outlet, Navigate } from 'react-router-dom';
import LogIn from '../pages/LogIn/logInPage';

// Example of a fake authentication check function

// ProtectedRoute component
const ProtectedRoute = (Component) => {
  const auth = localStorage.getItem('auth_token');
  // return  auth !== null ? 
  //   <Component/>
  //  : 
  //  <LogIn />
  // ;
  return auth ? <Outlet /> : <Navigate to="/login" />;
};

export default ProtectedRoute;
