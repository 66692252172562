import React from "react";
import Banner from "../../components/Banner/Banner";
import ContactOne from "../../components/Contact/ContactOne";
import TopPartners from "../../components/TopPartners/TopPartners";
import WhyChooseUs from "../../components/WhyChooseUs/WhyChooseUs";
import LayoutOne from "../../layouts/LayoutOne";
import Wallet from "../Wallet/walletDetails";
import WhoWeAre from "../../components/WhoWeAre/WhoWeAre";
import BannerBottom from "../../components/Banner/BannerBottom";

const Home = () => {
  return (
    <LayoutOne>
      <main className="fix">
        <Banner />
        {/* <BannerBottom/> */}
        {/* <WhoWeAre /> */}
        <WhyChooseUs />
        <TopPartners />

        {/* <Sales /> */}

        <div className="area-bg">
          {/* <Roadmap /> */}
          {/* <WhitePaper /> */}
        </div>


        <ContactOne />
      </main>
    </LayoutOne>
  );
};

export default Home;
