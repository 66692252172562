import React, { useState, useEffect } from 'react';
import ProfileTab from './profileTab'; // Import the child component
import { Link, NavLink, useLocation } from "react-router-dom";
import ViewUPIDetails from '../UPI/UPIDetails';
import userAvtar from "../../assets/img/images/user_avatar.png";
import { useHorizontalScroll } from "../../lib/hooks/useHorizontalScroll";
import auth from '../Auth/auth';
import ViewKYC from '../KYC/viewKYC';

const ProfilePage = ({ setAlert }) => {
  const [activeTab, setActiveTab] = useState('Profile');
  const location = useLocation();
  const userKYC = location.state?.userKYC || '';
  const phoneNumberVerified = false;
  // const kyc = userKYC !== '' ? true : false;
  const user = sessionStorage.getItem('userDetails');
  const [responseData, setResponseData] = useState({});
  var $redirectTo;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await auth.fetchUserData();
        if (response.status === 201 || response.status === 200) {
          setResponseData(response.data.escManageUserRegister);
        } else {
          setAlert({ message: 'Record not found.', type: 'success' });
        }
      } catch (error) {
        console.error('Error logging in:', error);
        setAlert({ message: 'An error occurred while logging in.', type: 'danger' });
      }
    };
  
    fetchData(); // Call the fetchData function
  }, []);

  if(responseData.isKycVerified === "N" || responseData.isKycVerified === "No") {
    $redirectTo = '/adharCard'
  } else if(responseData.isKycVerified === "P"){
    $redirectTo = '/panCard'
  } else if(responseData.isKycVerified === "B"){
    $redirectTo = '/bankDetailsForm'
  }
  const renderContent = () => {
    switch (activeTab) {
      case 'Profile':
        return (
          <section>
            <div className="profile-container">
              <div className="image-area mob-none">
                <img src={userAvtar} alt="User Avatar" />
              </div>
              <div className="details-area">
                <div className="user-details">
                  <h2 className="user-name">{(!responseData.aadhaarHolderName && (responseData.isKycVerified == "N" || responseData.isKycVerified == "No")) ? responseData && responseData.userName :  responseData && responseData.aadhaarHolderName }</h2>
                  <p className="user-email"></p>
                  <div className="user-phone mt-3">
                    <p className="phone-label">Phone Number:</p>
                    <p className="phone-number"> {phoneNumberVerified ? '' : (<li>
                      <Link to="/phone-verification">
                        verify Phone number
                      </Link>
                    </li>)}</p>
                  </div>
                  <div className="user-phone">
                    <p className="phone-label">Country:</p>
                    <p className="phone-number">India</p>
                  </div>
                  <div className="user-phone">
                    <p className="phone-label">UserId:</p>
                    <p className="phone-number">{responseData && responseData.userId}</p>
                  </div>
                  <a className="user-email underline" href='#'>Change Pasword ?</a>
                </div>
              </div>
            </div>
          </section>
        );
      case 'KYC Verification':
        return (
          <section>
            <div className="profile-container">
            <div className="image-area mob-none">
                <img src={userAvtar} alt="User Avatar" />
              </div>
            {!(responseData.isKycVerified == "I" || responseData.isKycVerified == "Y") ?
              <div className="details-area">
              <div className="user-details">
                <h6 className="user-name">{responseData && responseData.userName}</h6>
                <p>To make any kind of transaction KYC is important, without KYC no transaction can possible. Please verify now.</p>
                <h4 className="user-name text-blue">
                  <li>
                    <Link to={$redirectTo}>
                      Verify now
                    </Link>
                  </li></h4>
              </div></div> : <ViewKYC responseData={responseData} /> }
            </div>
          </section>
        );
      case 'Bank Account Details':
        return (
          <section className="">
            <div className="profile-container">
              <div className="image-area text-center mob-none">
              {(!responseData.accountNumber && responseData.isKycVerified == "B")
                ?
                <li>
                  <Link to="/BankDetailsForm">
                    + Add an Account
                  </Link>
                </li>
                : ''
              }
              </div>
              <div className="details-area">
                <div className="user-details">
                  <h2 className="user-name">BANK ACCOUNT DETAILS</h2>
                  {(responseData.accountNumber && (responseData.isKycVerified == "I" || responseData.isKycVerified == "Y")) ?
                  <>
                  <div className="user-phone">
                    <p className="phone-label">Account:</p>
                    <p className="phone-number">{responseData && responseData.accountNumber}</p>
                  </div>
                  <div className="user-phone">
                    <p className="phone-label">IFSC:</p>
                    <p className="phone-number">{responseData && responseData.accountIfsc}</p>
                  </div>
                  </>
                  :<h4 className='text-blue'><Link to={$redirectTo}> Please complete KYC.</Link></h4>
                  }
                </div>
              </div>
            </div>
          </section>
        );
      case 'UPI Details':
        return (
          <section className="">
            <div className="profile-container">
              <div className="image-area text-center">
                <li>
                  <Link to="/editUPIDetails">+Add new UPI</Link>
                </li>
              </div>
              <div className="details-area">
                <div className="user-details">
                  <ViewUPIDetails />
                </div>
              </div>
            </div>
          </section>
        );
      default:
        return null;
    }
  };
  const scrollRef = useHorizontalScroll();

  return (
    <div className="contact-form-wrap bg-dark">
      <div className="row">
        <div className="col-lg-12">
          <div
            className="bt-roadmap-scrollbar"
            ref={scrollRef}
            style={{ overflow: "auto" }}
          >
            <ProfileTab activeTab={activeTab} onTabChange={setActiveTab} />
          </div>
        </div>
      </div>

      {renderContent()}

    </div>
  );
};

export default ProfilePage;
