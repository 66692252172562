import React, { useState, useEffect } from 'react';

const Alert = ({ message, type, onClose }) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false);
      onClose();
    }, 10000); // Disappear after 10 seconds

    return () => clearTimeout(timer);
  }, [onClose]); // Added onClose as a dependency

  const handleClose = () => {
    setIsVisible(false);
    onClose();
  };

  return (
    <>
      {isVisible && (
        <div className={`alert alert-${type} bottom-right`}>
          <span>{message}</span>
          <button className="close-btn" onClick={handleClose}>
            &times;
          </button>
        </div>
      )}
    </>
  );
};

export default Alert;
