import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom'; // Import useHistory
import auth from '../Auth/auth';
import PageLoader from '../../components/PageLoader/PageLoader';

const OTPVerificationPage = ({ email, setAlert }) => {
 
    const history = useNavigate(); // Initialize useHistory
    const [otp, setOTP] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isTimerRunning, setIsTimerRunning] = useState(false);
    const [remainingTime, setRemainingTime] = useState(120); // 2 minutes in seconds
    const [mode, setMode] = useState('verify'); // 'verify' or 'resend'
    const location = useLocation();
    const [adhaar, setAdhaar] = useState(location.state ? location.state.adhaar : '');
    const [refId, setRefId] = useState(location.state ? location.state.refId : '');

    useEffect(() => {
        if (adhaar && refId) {
            setIsTimerRunning(true); // Start the timer if adhaar and refId are present
        }
    }, [adhaar, refId]);

    const handleResendOTP = () => {
        setIsTimerRunning(true);
        setRemainingTime(120);
        setMode('verify');
        try {
            const response = auth.verifyAdhaar({ adhaar: adhaar });
            if (auth.verifyAdhaar({ adhaar: adhaar })) {
               
                setAdhaar(response.aadhaarNo);
            setRefId(response.refId);
            }
        } catch (error) {
            setErrorMessage('An error catch while resending OTP.');
            console.error('Error resending OTP:', error);
        }
    };

      useEffect(() => {
        let timer;
        if (isTimerRunning) {
          timer = setInterval(() => {
            setRemainingTime((prevTime) => prevTime - 1);
          }, 1000);
        }
    
        return () => clearInterval(timer);
      }, [isTimerRunning]);

      useEffect(() => {
        if (remainingTime === 0) {
          setIsTimerRunning(false);
          setMode('resend');
        }
      }, [remainingTime]);
    const handleVerify = async (e) => {
        e.preventDefault();
        let setErrorMessage = '';
        let data = {
            aadhaarNo : adhaar,
            refId: refId,
            otp : otp
        }
        try {
            const response = await auth.verifyAdhaarOtp(data);

            if (response.kycApiResponseStatus == 200) {
                if(response.errorDescription != null){
                    setAlert({message:'This Aadhar has been already registered!', type: 'danger'});
                    return;
                } 
                history('/panCard',  { state: { response: response} });
                setAlert({message:'Aadhar number verified successfully!', type: 'success'});
            } else {
                const data = await response.json();
                setErrorMessage(data.message || 'An error occurred during sign-in.');
            }
            } catch (error) {
            setErrorMessage('An error occurred during sign-in.');
            console.error('Error signing in:', error);
            }
    };

    return (
        <>
        <section id="sales" className="chart-area sign-bg height-full">
            <div className="container">
                <div className="chart-inner">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-lg-6 col-md-10 order-0 order-lg-2">
                            <div className="chart-wrap wow fadeIn" data-wow-delay=".2s">
                                <div className="sign-form-wrap">
                                    <h4 className='text-center'>OTP Verification</h4>
                                    <p>We have sent an OTP to mobile number linked with your Aadhar. Please enter it below:</p>
                                    <form action="#">
                                        <div className="row">
                                            <div className="form-grp">
                                                <input
                                                    type="text"
                                                    value={otp}
                                                    onChange={(e) => setOTP(e.target.value)}
                                                    placeholder="Enter OTP"
                                                />
                                            </div>
                                        </div>
                                        <div className="submit-btn text-center">
                                            {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
                                            {isTimerRunning && <p>Resend OTP in: {Math.floor(remainingTime / 60)}:{(remainingTime % 60).toString().padStart(2, '0')}</p>}
                                            <button className='btn mr-2' onClick={handleVerify}>Verify</button>&nbsp;
                                            {mode !== 'verify' ? ( 
                                                <button className='btn ml-2' onClick={handleResendOTP}>Resend OTP</button>
                                            ) : ''}
                                        </div>
                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    );
};

export default OTPVerificationPage;
