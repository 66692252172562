import React, { useState, useEffect } from "react";
import WalletChart from './walletChart'
import CoinLayer from "./coinLayer";
import QRCodePopup from './addFund';
import qrImageURL from '../../assets/img/scanner/scanner.jpg'
import auth from '../Auth/auth';


const Wallet = ({setAlert}) => {
    const portfolioAmount = <i class="fa-solid fa-indian-rupee-sign"> 0.00</i>;
    const fiatBalance = <i class="fa-solid fa-indian-rupee-sign"> 0.00</i>;
    const currentInvestment = <i class="fa-solid fa-indian-rupee-sign"> 0.00</i>;

    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [qrImageUrl, setQrImageUrl] = useState('');
    const [responseData, setResponseData] = useState({});

    const fetchWallet = async () => {
        try {
            const response = await auth.getUserWallet();
            if (response.status === 201 || response.status === 200) {
                setResponseData(response.data);
            } else {
            }
        } catch (error) {
        }
    };

    useEffect(() => {
        fetchWallet();
    }, []);

  const handleShowPopup = () => {
    // Generate or set the QR data here based on your logic
    setQrImageUrl(qrImageURL);
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };
    return (
        <>
        <QRCodePopup isOpen={isPopupOpen} onClose={handleClosePopup} qrImageUrl={qrImageUrl} setAlert={setAlert} ariaHideApp={false} />

        <section id="contact" className="contact-area bg-white">
            <div className="container">
                <div className="contact-info-wrap mb-0 bg-white">
                    <div class="row justify-content-center mt-5">
                        <div class="col-lg-12 col-sm-12 mt-5">
                            <div class="wallet-top row">
                                <div class="wallet-actions large-control">
                                    <button class="btn" onClick={handleShowPopup}>Add Fund</button>
                                    <button class="btn">Transfer Fund</button>
                                    <button class="btn">Withdraw Fund</button>
                                </div>
                                <div class="wallet-left">
                                    <h2 class="user-name">Investment Wallet</h2>
                                    <div class="fiat-balance-label mt-3">Current Investment</div>
                                    <div class="fiat-balance-value">{responseData.amountInvested}</div>
                                    <br />
                                    <div class="fiat-balance-label">Invested Amount</div>
                                    <div class="fiat-balance-value">{responseData.totalAmount}</div>
                                </div>
                                <div class="wallet-center mob-none">
                                    <div class="portfolio-label">Portfolio</div>
                                    <div class="portfolio-amount">{responseData.portfolioBalance}</div>
                                    <WalletChart />
                                </div>
                                <div class="wallet-right">
                                    <h2 class="user-name">Fiat Wallet</h2>
                                    <div class="fiat-balance-label">Fiat Balance</div>
                                    <div class="fiat-balance-value">{responseData.fiatBalance}</div>
                                    <div class="wallet-actions mob-none">
                                        <button class="btn" onClick={handleShowPopup}>Add Fund</button>
                                        <button class="btn">Transfer Fund</button>
                                        <button class="btn">Withdraw Fund</button>
                                    </div>
                                </div>
                            </div>
                            <div class="wallet-bottom large-control">
                                <div class="wallet-center" style={{overflow: 'auto'}}>
                                    <h2 class="user-name mt-5">Portfolio</h2>
                                    <div class="portfolio-amount">{responseData.portfolioBalance}</div>
                                    <WalletChart />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    );
};

export default Wallet;

