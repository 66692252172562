import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import HeaderOne from '../../components/Header/HeaderOne';
import FooterOne from '../../components/Footer/FooterOne';
import auth from '../Auth/auth';

const ListPage = ({setAlert}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState('All');
  const [selectedUser, setSelectedUser] = useState(null); 
  const [showPopup, setShowPopup] = useState(false);
  const history = useNavigate();

  const fetchAllUserData = async () => {
    try {
      const response = await auth.fetchAllUserData();
      if (response.status === 201 || response.status === 200) {
        setData(response.data.escManageUserRegisterList);
        setLoading(false);
      } else {
        // Handle error response
      }
    } catch (error) {
      // Handle error
    }
  };

  useEffect(() => {
    fetchAllUserData();
  }, []);

  const handleApprove = async (userId) => {
    try {
      const response = await auth.approveUserById(userId);
      if (response.status === 200 || response.status === 201) {
        setAlert({ message: 'User Kyc approved.', type: 'success' })
        fetchAllUserData();
      } else {
        setAlert({ message: `Unable to approve user ${userId}.`, type: 'danger' });
      }
    } catch (error) {
      setAlert({ message: 'An error occurred while verifying the user.', type: 'danger' });
    }
  };

  const handleDecline = async (userId) => {
    try {
      const response = await auth.declineUserById(userId);
      if (response.status === 200 || response.status === 201) {
        setAlert({ message: 'User Kyc declined.', type: 'success' })
        fetchAllUserData();
      } else {
        setAlert({ message: `Unable to decline user ${userId}.`, type: 'danger' });
      }
    } catch (error) {
      setAlert({ message: 'An error occurred while verifying the user.', type: 'danger' });
    }
  };

  const handlePortfolio = async (userId) => {
    try {
      // Fetch user data for the selected user
      // const response = await auth.fetchUserData(userId);
      // const userData = await response.json();
      setSelectedUser(userId); // Set selected user
      setShowPopup(true); // Show popup
    } catch (error) {
      console.error('Error fetching user data:', error);
      // Handle error
      // setAlert({ message: 'An error occurred while fetching user data.', type: 'danger' });
    }
  };
  const closePopup = () => {
    setSelectedUser(null); // Reset selected user
    setShowPopup(false); // Hide popup
  };
  const handleReminder = async (userId) => {
    try {
      const response = await auth.reminderUserById(userId);
      if (response.status === 200 || response.status === 201) {
        setAlert({ message: 'User Kyc approved.', type: 'success' })
        fetchAllUserData();
      } else {
        setAlert({ message: `Unable to approve user ${userId}.`, type: 'danger' });
      }
    } catch (error) {
      setAlert({ message: 'An error occurred while verifying the user.', type: 'danger' });
    }
  };


  const tabNames = {
    'All': 'All',
    // 'P': 'InComplete Pan KYC',
    // 'B': 'InComplete Bank KYC',
    'I': 'InProgress KYC',
    'N': 'Non-Verified Users',
    'Y': 'Verified Users'
  };

  // Filter data based on selected tab
  const filteredData = data.filter(item => {
    if (activeTab === 'All') return true;
    return item.isKycVerified === activeTab;
  });

  return (
    <>
    <div>
      {/* Your existing JSX code */}
      {showPopup && (
        <div className="popup">
          <div className="popup-content">
            <button onClick={closePopup}>Close</button>
            <h2>User Details</h2>
            {selectedUser && (
              <div>
                <p>Name: {selectedUser.name}</p>
                <p>Email: {selectedUser.email}</p>
                {/* Display other user details as needed */}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
      <section>
        <div className='bg-white table-padding pt-1'>
          {loading ? (
            <p>Loading...</p>
          ) : (
            <>
              {/* Render tabs */}
              <div className="tabs mt-3 mb-3 mobList" >
                {Object.keys(tabNames).map(status => (
                  <button
                    key={status}
                    className={status === activeTab ? 'active-tab btn btn-user-list' : 'btn btn-user-list'}
                    onClick={() => setActiveTab(status)}
                  >
                    {tabNames[status]}
                  </button>
                ))}
              </div>
              <div class="row" style={{overflow: 'auto'}}>
              <table className="table table-striped table-hover table-sm">
                <thead>
                  <tr className='text-center'>
                    <th>User ID</th>
                    <th>Aadhar Name</th>
                    <th>Pan Name</th>
                    <th>Bank Name</th>
                    <th>KYC Status</th>
                    <th>
                      {activeTab === 'Y' && activeTab !== 'All' ? "Portfolio Details" :
                        (activeTab === 'N' && activeTab !== 'All' ? "Send Reminder" :
                          (activeTab !== 'Y' && activeTab !== 'All' && activeTab !== 'N') && <>Action</>
                        )
                      }
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filteredData.map(item => (
                    <tr key={item.id} className='text-center'>
                      <td>{item.userName}</td>
                      <td>{item.aadhaarHolderName}</td>
                      <td>{item.panHolderName}</td>
                      <td>{item.accountHolderName}</td>
                      <td>{item.isKycVerified}</td>
                      <td>
                        {/* Disable actions on Y tab */}
                        {(activeTab === 'Y' && activeTab !== 'All') 
                          ? <button className="btn btn-outline btn-success btn-sm btn-user-list" onClick={() => handlePortfolio(item.userId)}>View Portfolio</button>
                          : (activeTab === 'N' && activeTab !== 'All')
                            ? <button className="btn btn-outline btn-success btn-sm btn-user-list" onClick={() => handleReminder(item.userId)}>Send Reminder</button>
                            : ((activeTab !== 'Y' && activeTab !== 'All' && activeTab !== 'N')) &&
                            (<>
                              <button className="btn btn-outline btn-success btn-sm btn-user-list" onClick={() => handleApprove(item.userId)}>Approve</button>
                              <button className="btn btn-outline btn-danger btn-sm btn-user-list" onClick={() => handleDecline(item.userId)}>Decline</button>
                            </>
                            )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              </div>
            </>
          )}
        </div>
      </section>
    </>
  );
};

export default ListPage;
