import React, { useState } from 'react';
import { Link, NavLink, useLocation } from "react-router-dom";
import cn from "classnames";
import { useNavigate } from 'react-router-dom'; // Import useHistory
import axios from 'axios';
import Alert from '../../components/Alert/Alert.jsx';

const SignIn = ({ setAlert }) =>  {
  const navigate = useNavigate(); // Initialize useHistory
  const [userName, setUserName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNo, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const handlePasswordToggle = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const isLocal = window.location.hostname === 'localhost';
  const baseUrl = isLocal ? 'http://localhost:8082' : 'https://evershinecosmic.com';
  const userSignIn = `${baseUrl}/api/v1/auth/register`;

  const { hash, pathname } = useLocation();
  const handleCancel = () => {
    navigate('/'); // This function navigates to the previous page in the history.
  };
  const handleEmailChange = (event) => {
    const enteredEmail = event.target.value;
    setEmail(enteredEmail);
  
    // Extracting userId from email
    //const extractedUserId = enteredEmail.split("@")[0];
    setUserName(enteredEmail);
  };
  const handleSignIn = async (e) => {
    e.preventDefault();
    if (!userName || !email || !password || !rememberMe || !phoneNo) {
      setErrorMessage('All fields are required.');
      return;
    }
        
    if (!/(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}/.test(password)) {
            setErrorMessage('Password must contain at least 1 uppercase, 1 lowercase, 1 number, and 1 special character, and be at least 8 characters long. ');
      return;
    }
    
    if (phoneNo.length !== 10) {
            setErrorMessage('Phone number must be 10 digits long. ');
      return;
    }
    
    try {
      const response = await axios.post(userSignIn, {
        "userName": userName,
        "emailId": email,
        "password": password,
        "phoneNo": phoneNo,
        "action": "Manage",
        "userId": userName,
        "activateFlag": "Y",
        "displayUserId": userName,
        "userType": "User",
        "isKycVerified": "No"
      }, {
        headers: {
          'Content-Type': 'application/json',
        }
      });
      if (response.status === 201 || response.status === 200) {
        setAlert({message:'User created successfully!', type: 'success'});
        navigate('/login', { state: { userEmail: email } });
      } else {
        setAlert({message:'Failed to create user.', type: 'danger'});
      }
    } catch (error) {
      console.error('Error creating user:', error);
      setAlert({message:'An error occurred while creating the user.', type: 'danger'});
    }

  };

  const countries = [
    'India',
    'United States',
    'Canada',
    'United Kingdom',
    'Australia',
  ];

  return (
    <section id="contact" className="chart-area sign-bg" style={{height: '700px', overflow: 'auto'}}>
      <div className="container">
        <div className="chart-inner">
          <div className="row align-items-center justify-content-center" >
            <div className="col-lg-6 col-md-10 order-0 order-lg-2">
              <div className="chart-wrap wow fadeIn" data-wow-delay=".2s">
                <li className={cn("text-end", pathname == "/logIn" && "active sign")}>
                  <Link to="/login">
                    Log In
                  </Link>
                </li>
                <div className="sign-form-wrap">
                  <h4 className='text-center'>Sign Up</h4>
                  <form>
                    
                    <div className="row">
                      <div className="form-grp">
                        <label>Email</label>
                        <input
                          type="email"
                          value={email}
                          onChange={handleEmailChange}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-grp">
                        <label>Your User ID is</label>
                        <input
                          type="userName"
                          value={userName}
                          onChange={(e) => setUserName(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-grp">
                        <label>Phone Number</label>
                        <input
                          type="phoneNumber"
                          value={phoneNo}
                          onChange={(e) => setPhone(e.target.value)}
                          maxLength={10}
                        />
                      </div>
                    </div>
                    <div className="form-grp">
                      <label>Password</label>
                      <div className="password-input-container">
                      <input
                        type={showPassword ? 'text' : 'password'}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <button type="button" style={{ color: 'black' }} onClick={handlePasswordToggle}>
                        {showPassword ? <i class="fa fa-eye" aria-hidden="true"></i> : <i class="fa fa-eye-slash" aria-hidden="true"></i>}
                      </button>
                      </div>
                    </div>
                    <div className="form-grp">
                      <label>Country</label>
                      <select
                        value="India"
                        onChange={(e) => setSelectedCountry(e.target.value)}
                        disabled
                      >
                        {countries.map((country, index) => (
                          <option key={index} value={country}>
                            {country}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="form-grp row text-left-30">
                      <div className='col-sm-12 d-flex'>
                        <div className='col-sm-1'>
                          <input
                            type="checkbox"
                            checked={rememberMe}
                            onChange={(e) => setRememberMe(e.target.checked)}
                            required
                          />
                        </div>
                        <div className='col-sm-1'>
                        </div>
                        <div className='col-sm-10 terms'>
                          <span className=''>
                            Agree to EvershineCosmic's Terms
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="submit-btn text-center">
                      {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
                      <button className='btn' onClick={handleSignIn}>Sign Up</button> &nbsp;&nbsp;
                      <button className='btn' onClick={handleCancel}>Cancel</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SignIn;
