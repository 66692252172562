import { Routes, Route } from 'react-router-dom';
import Home from "./pages/Home/Home";
import { useEffect, useState } from "react";
import WOW from "wow.js";
import HomeTwo from "./pages/Home/HomeTwo";
import Blog from "./pages/Blog/Blog";
import About from "./components/About/About.jsx";
import Contact from "./components/Contact/ContactMain.jsx";
import BlogDetailsPage from "./pages/BlogDetails/BlogDetailsPage";
import SignIn from './pages/SignIn/signInPage';
import OTPverification from './pages/SignIn/OTPVerificationPage';
import Phoneverification from './pages/Profile/phoneVerification';
import PanForm from './pages/VerificationForm/PanForm';
import AdharForm from './pages/VerificationForm/adharForm';
import LogIn from './pages/LogIn/logInPage';
import ProfilePage from './pages/Profile/profileMain';
import BankDetailsForm from './pages/VerificationForm/BankDetailsForm';
import WalletMain from './pages/Wallet/walletMain.jsx'
import WalletChart from './pages/Wallet/walletChart';
import { useLocation } from "react-router-dom";
import Alert from "./components/Alert/Alert.jsx";
import ForgotPassword from "./pages/LogIn/forgotPassword.jsx";
import ResetPassword from "./pages/LogIn/resetPassword.jsx";
import ProtectedRoute from './routes/ProtectedRoute.jsx';
import Admin from './pages/User/main.jsx'
import SessionChecker from './utils/sessionChecker.js';
import Investment from './pages/Investments/main.jsx';
import Transaction from './pages/Transactions/main.jsx';

function App() {
  const [alert, setAlert] = useState(null);

  const handleAlertClose = () => {
    setAlert(null);
  };
  useEffect(() => {
    const wow = new WOW({
      boxClass: "wow",
      animateClass: "animated",
      offset: 0,
      mobile: false,
      live: true,
    });
    wow.init();
  }, []);

  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  // Check server status or session expiration
  useEffect(() => {
    const checkSession = setInterval(() => {
     
      if (SessionChecker.isSessionExpired()) {
        clearSession();
      }
    }, 60000); // Check session status every 1 minute (adjust as needed)
    return () => clearInterval(checkSession);
  }, []);

  const clearSession = () => {
    localStorage.removeItem('auth_token');
  };

  return (
    <>
      {alert && (
        <Alert
          message={alert.message}
          type={alert.type}
          onClose={handleAlertClose}
        />
      )}
      <Routes>
        <Route exact path='otp-verification' element={<ProtectedRoute />}>
          <Route exact path="/otp-verification" element={<OTPverification setAlert={setAlert} />} />
        </Route>
        <Route exact path='phone-verification' element={<ProtectedRoute />}>
          <Route exact path="/phone-verification" element={<Phoneverification />} />
        </Route>
        <Route exact path='profilePage' element={<ProtectedRoute />}>
          <Route exact path="/profilePage" element={<ProfilePage setAlert={setAlert} />} />
        </Route>
        <Route exact path='panCard' element={<ProtectedRoute />}>
          <Route exact path="/panCard" element={<PanForm setAlert={setAlert} />} />
        </Route>
        <Route exact path='adharCard' element={<ProtectedRoute />}>
          <Route exact path="/adharCard" element={<AdharForm setAlert={setAlert} />} />
        </Route>
        <Route exact path='bankDetailsForm' element={<ProtectedRoute />}>
          <Route exact path="/bankDetailsForm" element={<BankDetailsForm setAlert={setAlert} />} />
        </Route>
        <Route exact path='wallet' element={<ProtectedRoute />}>
          <Route exact path="/wallet" element={<WalletMain setAlert={setAlert} />} />
        </Route>
        <Route exact path='walletChart' element={<ProtectedRoute />}>
          <Route exact path="/walletChart" element={<WalletChart setAlert={setAlert} />} />
        </Route>
        <Route exact path='admin' element={<ProtectedRoute />}>
          <Route exact path="/admin" element={<Admin setAlert={setAlert} />} />
        </Route>
        <Route exact path='investment' element={<ProtectedRoute />}>
          <Route exact path="/investment" element={<Investment setAlert={setAlert} />} />
        </Route>
        <Route exact path='transaction' element={<ProtectedRoute />}>
          <Route exact path="/transaction" element={<Transaction setAlert={setAlert} />} />
        </Route>
        <Route exact path="login" element={<LogIn setAlert={setAlert} />} />
        <Route exact path="reset-password" element={<ResetPassword />} />
        <Route exact path="forgot-password" element={<ForgotPassword />} />
        <Route exact path="/" element={<Home />} />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/contact" element={<Contact />} />
        <Route exact path="sign" element={<SignIn setAlert={setAlert} />} />
      </Routes>
    </>
  );
}

export default App;
