import React, { useRef, useEffect, useCallback } from 'react';

const AutoplayVideo = ({ src, width }) => {
  const videoRef = useRef(null);
  const observerRef = useRef(null);

  const handleIntersection = useCallback(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // Video is in the viewport, autoplay it
          if (videoRef.current) {
            videoRef.current.play().catch((error) => {
              // Autoplay failed, handle the error if necessary
              console.error('Autoplay failed:', error);
            });
          }
        } else {
          // Video is out of the viewport, pause it
          if (videoRef.current) {
            videoRef.current.pause();
          }
        }
      });
    },
    []
  );

  useEffect(() => {
    const options = {
      root: null, // viewport
      rootMargin: '0px',
      threshold: 0.5, // Trigger when 50% of the video is in the viewport
    };

    const observer = new IntersectionObserver(handleIntersection, options);
    observerRef.current = observer;

    // Start observing the video element
    if (videoRef.current) {
      observer.observe(videoRef.current);
    }

    // Cleanup the observer when the component unmounts
    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, [handleIntersection]);

  return (
    <video
      ref={videoRef}
      loop
      preload="auto"
      playsInline
      src={src}
      controls
      className={width}
    />
  );
};

export default AutoplayVideo;
