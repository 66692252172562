import React from 'react';
import verifyProcess from "../../assets/img/images/user_avatar.png";
import verified from "../../assets/img/images/user_avatar.png";

const ViewKYC = ({ responseData }) => {
  const isKyc = responseData.isKycVerified;
  return (
    <div className="profile-container">
    <div className="image-area mob-none">
    <img src={isKyc != "N" ? verifyProcess : verified} alt="User Avatar" />
    </div>
    <div className="details-area">
    <div className="user-details">
      <h2 className="user-name">KYC Details</h2>
      <p className='text-yellow'>{isKyc == "I"  ? "Your verification is under process" : "KYC verified"}</p>
      {responseData && (
        <div>
          <div className="user-phone">
            <p className="phone-label">PAN:</p>
            <p className="phone-number">{responseData.panNumber}</p>
          </div>
          <div className="user-phone">
            <p className="phone-label">Aadhar:</p>
            <p className="phone-number">{responseData.aadhaarNumber}</p>
          </div>
          {/* Add more fields here as needed */}
        </div>
      )}
    </div>
    </div>
    </div>
  );
};

export default ViewKYC;
