import React from "react";
import arjun from "../../assets/img/team/arjun_reyat.jpg";
import Indra from "../../assets/img/team/indrajeet_kr_singh.jpg"; //src\assets\img\team\indrajeet_kr_singh.jpg

const TeamOne = () => {
  const team_members = [
    {
      src: arjun,
      name: "Arjun Reyat",
      designation: "Founder & CO",
    },
    {
      src: Indra,
      name: "Indrajeet Kr Singh",
      designation: "Founder & CO",
    },
  ];

  return (
    <section id="about" className="about-area pb-50 pl-2">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-6">
            <div className="section-title text-center mb-70">
            </div>
          </div>
        </div>
        {/* <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="about-img wow fadeInLeft" data-wow-delay=".2s">
              <img src={arjun} alt="Arjun Img" />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="about-content wow fadeInRight" data-wow-delay=".2s">
              <div className="section-title mb-30">
                <h2 className="title">
                  Arjun Reyat
                </h2>
              </div>
              <p className="align-justify">
                Hello, I'm Arjun Reyat(Mayank), owner of Evershine Cosmic. I come from Patna, Bihar, and I'm a proud alumnus of CMRIT Bangalore, where I earned my degree in Computer Science Engineering.

                After working in the private sector for seven years, I began exploring the exciting world of blockchain technology in 2018. I quickly became fascinated by the possibilities it holds for transforming the way we think about money and financial transactions.

                Over the years, reading more than 300+ non-fiction books on history, psychology, and economics has given me a unique perspective on the world and how we can create value in people's lives.

                My mission is to use the power of blockchain to create a more equitable and sustainable future for everyone. I believe that by investing in this transformative technology, we can unlock new opportunities for growth and innovation.

                At Evershine Cosmic, we are dedicated to building a better world through blockchain. If you're an individual looking to invest in blockchain industry, we're here to help you achieve your goals.

                Thank you for your interest in our company, and we look forward to helping you on your journey to success.
              </p>
              <div className="footer-content">
                  <ul className="footer-social">
                    <li>
                      <a href="https://twitter.com/arjun_reyat?t=nvUmHU6J882LoDl0kL4HjA&s=09" target="_blank">
                      <i className="fa-brands fa-x-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://instagram.com/arjun_reyat?igshid=YmMyMTA2M2Y=" target="_blank">
                        <i className="fab fa-instagram"></i>
                      </a>
                    </li>
                  </ul>
                </div>
            </div>
          </div>
        </div> */}
        <div className="row align-items-center pt-130">
          <div className="col-lg-6">
            <div className="about-img wow fadeInLeft" data-wow-delay=".2s">
              <img src={Indra} alt="Indra Img" />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="about-content wow fadeInRight" data-wow-delay=".2s">
              <div className="section-title mb-30 ml-5">
                <h2 className="title">
                  Indrajeet Kr. Singh
                </h2>
              </div>
              <p className="align-justify">
                Greetings, I'm Indrajeet Kumar Singh, proprietor of our enterprise. My upbringing took place in Chhapra, Bihar. Afterward, I pursued Electronics and Communication Engineering at MITM Jamshedpur. Following my graduation, I served as a full-stack developer at a global corporation for a span of five years before entering into the Indian Railways.

                Whilst employed by the government, I recognized my genuine ardor for enhancing people's lives. Consequently, in 2019, I began exploring the potential of blockchain technology to revolutionize the world. I was inspired to launch a company focused on investing in blockchain.

                My objective is to generate value in people's lives in every feasible manner. Through our company, I aim to assist individuals in comprehending and capitalizing on the possibilities presented by blockchain technology. We appreciate your consideration of our company as a partner on your expedition towards achieving financial autonomy and prosperity
              </p>
              <div className="footer-content">
                  <ul className="footer-social">
                    <li>
                      <a href="https://twitter.com/singh__ind" target="_blank">
                      <i className="fa-brands fa-x-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.instagram.com/singh__ind/" target="_blank">
                        <i className="fab fa-instagram"></i>
                      </a>
                    </li>
                  </ul>
                </div>
            </div>
          </div>

        </div>
      </div>
    </section>
  );
};

export default TeamOne;
