import React, { useState } from 'react';

function ForgotPassword() {
  const [email, setEmail] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    //send reset link in email from backend
    console.log('Password reset requested for email:', email);
  };

  return (
    <section id="sales" className="chart-area sign-bg">
      <div className="container">
        <div className="chart-inner">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-6 col-md-10 order-0 order-lg-2">
              <div className="chart-wrap wow fadeIn" data-wow-delay=".2s">
                <div className="sign-form-wrap mb-3">
                  <h4 className='text-center mt-2 mb-2'>Forgot Password</h4>
                  <form onSubmit={handleSubmit}>
                    <div className="row mt-2">
                      <div className="form-grp">
                        <label>Email</label>
                        <input
                          type="text"
                          value={email}
                          onChange={(e) => setEmail(e.target.email)}
                        />
                      </div>
                    </div>
                    
                    <div className="submit-btn text-center mt-5">
                      <button type="submit" className='btn'>Submit</button> &nbsp; &nbsp;
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ForgotPassword;
