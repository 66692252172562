import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import auth from '../Auth/auth';

const BankDetailForm = ({setAlert}) => {
  const [accountHolder, setAccountHolder] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [panName, setPanName] = useState('');
  const [branchName, setBranchName] = useState('');
  const [ifscCode, setIfscCode] = useState('');
  const [submitMessage, setSubmitMessage] = useState('');
  const history = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    let setErrorMessage = '';
    let data = {
      ifsc: ifscCode,
      bankAccountNo: accountNumber,
      nameAsAadhaar: accountHolder,
      nameAsPan: panName
  }
    // try {
    //   const response = await auth.verifyBankDetails(data);

    //   if (response.kycApiResponseStatus == 200) {
    //       history('/profilePage',  { state: { response: response} });
    //       setAlert({message:'We got your Bank details. Thank you for submitting.', type: 'success'});
    //       sessionStorage.removeItem('isKycVerified');
    //       sessionStorage.setItem('isKycVerified', 'Y');
    //   } else {
    //       const data = await response.json();
    //       setErrorMessage(data.message || 'An error occurred during sign-in.');
    //   }
    //   } catch (error) {
    //   setErrorMessage('An error occurred during sign-in.');
    //   console.error('Error signing in:', error);
    //   }

  try {
    const response = await auth.verifyBankDetails(data);

    if (response.kycApiResponseStatus == 200) {
        // Call the verifyNow API
        // const verifyNowResponse = await auth.verifyNow(); // Replace verifyNow with the actual function or API call

        // // Check if verifyNow succeeds
        // if (verifyNowResponse.status === 200) {
        //     // Update session to 'Y'
        //     sessionStorage.setItem('isKycVerified', 'Y');
        // } else {
        //     // Update session to 'P' (pending)
        //     sessionStorage.setItem('isKycVerified', 'P');
        //     setAlert({message:'Request for manual Kyc.', type: 'success'});
        // }

        // Continue with the rest of your logic
        //sessionStorage.setItem('isKycVerified', 'P');
        history('/profilePage',  { state: { response: response} });
        setAlert({message:'We got your Bank details. Thank you for submitting.', type: 'success'});
    } else {
        const data = await response.json();
        setErrorMessage(data.message || 'An error occurred during sign-in.');
    }
} catch (error) {
    setErrorMessage('An error occurred during sign-in.');
    console.error('Error signing in:', error);
}

};

  return (
    <section id="bank-details" className="chart-area sign-bg">
      <div className="container">
        <div className="chart-inner">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-6 col-md-10 order-0 order-lg-2">
              <div className="chart-wrap wow fadeIn" data-wow-delay=".2s">
                <div className="sign-form-wrap">
                  <h2>Bank Detail Form</h2>
                  <form onSubmit={handleSubmit}>
                    <div className="form-grp">
                      <label htmlFor="accountHolder">Account Holder:</label>
                      <input
                        type="text"
                        id="accountHolder"
                        value={accountHolder}
                        onChange={(e) => setAccountHolder(e.target.value)}
                        required
                      />
                    </div>
                    <div className="form-grp">
                      <label htmlFor="accountNumber">Account Number:</label>
                      <input
                        type="text"
                        id="accountNumber"
                        value={accountNumber}
                        onChange={(e) => setAccountNumber(e.target.value)}
                        required
                      />
                    </div>
                    <div className="form-grp">
                      <label htmlFor="panName">Name As Pan: </label>
                      <input
                        type="text"
                        id="panName"
                        value={panName}
                        onChange={(e) => setPanName(e.target.value)}
                        required
                      />
                    </div>
                    {/*<div className="form-grp">
                      <label htmlFor="branchName">Branch Name:</label>
                      <input
                        type="text"
                        id="branchName"
                        value={branchName}
                        onChange={(e) => setBranchName(e.target.value)}
                        required
                      />
                    </div> */}
                    <div className="form-grp">
                      <label htmlFor="ifscCode">IFSC Code:</label>
                      <input
                        type="text"
                        id="ifscCode"
                        value={ifscCode}
                        onChange={(e) => setIfscCode(e.target.value)}
                        required
                      />
                    </div>
                    <div className="submit-btn text-center">
                      <button className="btn" type="submit">
                        Submit
                      </button>
                    </div>
                  </form>
                  <p>{submitMessage}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BankDetailForm;
