import React, { useEffect, useState } from "react";
import SlickSlider from "../SlickSlider/SlickSlider";
import img01 from "../../assets/img/images/about_img01.png";
import img02 from "../../assets/img/images/about_img02.png";
import icon03 from "../../assets/img/icon/choose_icon03.svg";
import what_do_we_do from "../../assets/img/images/what_do_we_do.png";
import what_are_we from "../../assets/img/images/what_are_we.png";
import video from "../../assets/video/KnowUS.mp4";
import WhoWeAreItem from "../WhoWeAre/WhoWeAreItem";
import WhoWeAreItemReverse from "../WhoWeAre/WhoWeAreItemReverse";
import WhoWeAreVideo from "../WhoWeAre/WhoWeAreVideo";


const WhyChooseUs = () => {
  const slickSettings = {
    dots: false,
    infinite: true,
    speed: 1000,
    autoplay: true,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };


 const whatAreWe = {
  src1: what_are_we,
      alt: "",
      link: "/",
      subtitle: "WHAT ARE WE?",
      description: "Investing money can be a smart way to grow your wealth and secure your financial future. However with so many investment options available, it can be overwhelming where to start. That's where you choose EVERSHINE COSMIC, which will provide you the tools and resources needed to make informed investment decisions, no matter the level of your expertise or experience. Our website will provide the investment opportunity to help you achieve your financial goals..",
    }
 const Whatdowedo = {
  src1: what_do_we_do,
      alt: "",
      link: "/",
      subtitle: "WHAT DO WE DO?",
      description: "At Evershine Cosmic, we believe that blockchain is the future of currency and has the potential to transform the way we transact and conduct business. We have over 5 years of experience in investing in blockchain companies and have a proven track record of delivering profitable returns to our clients. We are investors who are all in when it comes to investing in blockchain companies. We understand the blockchain industry inside out and know how to identify the companies with the highest potential for growth and profitability."
    }
 const Howdowodo = {
      src: video,
    }
  

  const initalState = 0;
  const [count, setCount] = useState(initalState);

  useEffect(() => {
    const interval = setInterval(() => {
      setCount((old) => (old == 1 ? 0 : old + 1));
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  // const toMultiply = 100 / slider_items.length;

  return (
    <>
    <section className="choose-area mt-5">
      <div className="container">
        <div className="row justify-content-center">
          <WhoWeAreItem item={whatAreWe} />
          <br/>
          <WhoWeAreItemReverse item={Whatdowedo} />
          <br/>
          <div className="row justify-content-center">
          <div className="col-xl-6">
            <div className="section-title text-center">
              <span className="sub-title" id="HowDoWeDo">How do we do</span>
            </div>
          </div>
        </div>
        <WhoWeAreVideo item={Howdowodo} />
      </div>
      </div>
    </section>
</>
  );
};

export default WhyChooseUs;
