import React from "react";
import HeaderOne from "../../components/Header/HeaderOne";
import TeamOne from "../../components/Team/TeamOne";
import WhoWeAre from "../../components/WhoWeAre/WhoWeAre";
import BannerBottom from "../Banner/BannerBottom";
import FooterOne from "../Footer/FooterOne";
import WhyChooseUs from "../WhyChooseUs/WhyChooseUs";


const About = () => {
  return (
    <>    
    <HeaderOne/>
    <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <div className="banner-content text-center">
              <h2 className="banner-bottom-title">
                Evershine Cosmic
              </h2>
            </div>
            <div>
              <p>We are an investment business with a mission to create value in people's lives. Our approach is client-centric, and we measure our success by the success of our clients. We provide innovative solutions that simplify the lives of individuals, and we only make money after we have delivered value to our clients.</p>
            </div>
          </div>
        </div>
      </div>
    <WhoWeAre />
    <TeamOne />
    <FooterOne/>
    </>
  );
};

export default About;
