import React from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

const WhoWeAreItem = (props) => {
  const { pathname } = useLocation();
    return (
        <div className="row align-items-center mt-5 mb-5">
          <div className="col-lg-6 order-2 order-lg-0">
            <div className="about-img wow fadeInLeft" data-wow-delay=".2s">
              <img src={props.item.src1} alt="" />
              <img src={props.item.src2} alt="" className="img-two" />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="about-content wow fadeInRight" data-wow-delay=".2s">
              <div className="section-title mb-30">
                <span className= {pathname !== "/about" ? "sub-title" : ''}>{props.item.subtitle}</span>
                <h2 className="title">
                {props.item.title}
                </h2>
              </div>
              <p className="align-justify">{props.item.description}</p>
            </div>
          </div>
        </div>
    );
};

export default WhoWeAreItem;
