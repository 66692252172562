import React from "react";
import { Link } from "react-router-dom";
import HeaderOne from "../../components/Header/HeaderOne";
import FooterOne from "../../components/Footer/FooterOne";
import ListPage from "../../pages/User/listPage.jsx";

const userMain = ({setAlert}) => {
    return (
        <>
        <HeaderOne/>
        <ListPage setAlert={setAlert}/>
        <FooterOne/>
        </>
    );
};

export default userMain;
